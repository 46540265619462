import { useState, useEffect } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { MenuItem, Select } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartData,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { FiUsers } from "react-icons/fi";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BsChatLeftText } from "react-icons/bs";
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { AiOutlineAudio } from "react-icons/ai";
import DashboardCard from "./DashboardCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

ChartJS.register(ChartDataLabels);

interface CustomDataset {
  label: string;
  data: number[];
  backgroundColor: string | string[];
  borderColor: string | string[];
  borderWidth: number;
  usage: string[];
}

function AdminDashboard() {
  interface Dashboard {
    Number_of_all_questions: string;
    Number_of_tts_questions: string;
    Number_of_normal_questions: string;
    Number_of_stt_questions: string;
    total_input_tokens: string;
    total_output_tokens: string;
    total_stt_minutes: string;
    total_tts_characters: string;
    number_of_users: string;
    input_token_cost: string;
    output_token_cost: string;
    stt_cost: string;
    total_cost: string;
    tts_cost: string;
  }

  interface SchoolData {
    id: string;
    name: string;
    max_students: string;
    current_users: string;
  }

  interface RatingsData {
    average_rating: number;
    course_id: string;
    course_name: string;
  }

  interface RatingsData {
    average_rating: number;
    course_id: string;
    course_name: string;
  }

  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<Dashboard>();
  const [ratingsData, setRatingsData] = useState<RatingsData[]>();
  const [schoolsData, setSchoolsData] = useState<SchoolData[] | null>(null);
  const [selectedSchool, setSelectedSchool] = useState("all");
  const [selectedModel, setSelectedModel] = useState("all");
  const [selectedTtsModel, setSelectedTtsModel] = useState("all");
  const [selectedSttModel, setSelectedSttModel] = useState("all");
  const today = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const [fromDate, setFromDate] = useState<string>(formatDate(oneYearAgo));
  const [toDate, setToDate] = useState<string>(formatDate(today));

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
      setSchoolsData
    );
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_ADMIN_DASHBOARD_RATINGS}`,
      setRatingsData
    );
  }, []);

  useEffect(() => {
    setLoading(true);

    async function fetchData1() {
      try {
        const formData = new FormData();

        formData.append("school_id", selectedSchool);
        formData.append("model", selectedModel);
        formData.append("tts_model", selectedTtsModel);
        formData.append("stt_model", selectedSttModel);

        if (fromDate !== undefined) {
          formData.append("from", fromDate);
        }
        if (toDate !== undefined) {
          formData.append("to", toDate);
        }

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ADMIN_DASHBOARD_DATA}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();

    setLoading(false);
  }, [
    selectedSchool,
    fromDate,
    toDate,
    selectedModel,
    selectedTtsModel,
    selectedSttModel,
  ]);

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFromDate = e.target.value;
    if (toDate && new Date(newFromDate) > new Date(toDate)) {
      toast.error("From date cannot be after to date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setFromDate(newFromDate);
    }
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newToDate = e.target.value;
    if (fromDate && new Date(newToDate) < new Date(fromDate)) {
      toast.error("To date cannot be before from date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setToDate(newToDate);
    }
  };

  const prepareQuestionTypeData = () => {
    return {
      labels: ["Normal", "TTS", "STT"],
      datasets: [
        {
          label: "Question Types",
          data: [
            Number(dashboardData?.Number_of_normal_questions || 0),
            Number(dashboardData?.Number_of_tts_questions || 0),
            Number(dashboardData?.Number_of_stt_questions || 0),
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 159, 64, 0.6)",
            "rgba(75, 192, 192, 0.6)",
          ],
          borderColor: [
            "rgba(54, 162, 235, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(75, 192, 192, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareCostData = (): ChartData<"bar", number[], string> & {
    datasets: CustomDataset[];
  } => {
    return {
      labels: ["Input Tokens", "Output Tokens", "TTS", "STT"],
      datasets: [
        {
          label: "Costs ($)",
          data: [
            Number(dashboardData?.input_token_cost || 0),
            Number(dashboardData?.output_token_cost || 0),
            Number(dashboardData?.tts_cost || 0),
            Number(dashboardData?.stt_cost || 0),
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 99, 132, 0.6)",
            "rgba(255, 159, 64, 0.6)",
            "rgba(75, 192, 192, 0.6)",
          ] as string[],
          borderColor: [
            "rgba(54, 162, 235, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(75, 192, 192, 1)",
          ] as string[],
          borderWidth: 1,
          usage: [
            `${dashboardData?.total_input_tokens || 0} Tokens`,
            `${dashboardData?.total_output_tokens || 0} Tokens`,
            `${dashboardData?.total_tts_characters || 0} Characters`,
            `${dashboardData?.total_stt_minutes || 0} Minutes`,
          ],
        },
      ],
    };
  };

  const prepareRatingsData = (): ChartData<"bar"> => {
    if (!ratingsData) {
      return {
        labels: [],
        datasets: [
          {
            label: "Average Rating",
            data: [],
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      };
    }

    return {
      labels: ratingsData.map((item) => item.course_name),
      datasets: [
        {
          label: "Average Rating",
          data: ratingsData.map((item) => item.average_rating),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const cardData = [
    {
      title: "Total Users",
      value: dashboardData?.number_of_users || 0,
      icon: <FiUsers className="h-6 w-6 text-blue-500" />,
    },
    {
      title: "Input Tokens",
      value: Number(dashboardData?.total_input_tokens || 0).toLocaleString(),
      cost: Number(dashboardData?.input_token_cost || 0).toFixed(4),
      icon: <HiOutlineDocumentText className="h-6 w-6 text-purple-500" />,
    },
    {
      title: "Output Tokens",
      value: Number(dashboardData?.total_output_tokens || 0).toLocaleString(),
      cost: Number(dashboardData?.output_token_cost || 0).toFixed(4),
      icon: <BsChatLeftText className="h-6 w-6 text-green-500" />,
    },
    {
      title: "TTS Usage",
      value: Number(dashboardData?.total_tts_characters || 0).toLocaleString(),
      cost: Number(dashboardData?.tts_cost || 0).toFixed(4),
      icon: <MdOutlineRecordVoiceOver className="h-6 w-6 text-orange-500" />,
    },
    {
      title: "STT Usage",
      value: Number(dashboardData?.total_stt_minutes || 0).toLocaleString(),
      cost: Number(dashboardData?.stt_cost || 0).toFixed(4),
      icon: <AiOutlineAudio className="h-6 w-6 text-teal-500" />,
    },
  ];

  return (
    <section
      className="overflow-scroll h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="p-2">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          Dashboard Analytics
        </h2>
        <div className="space-y-6">
          <div className="grid md:grid-cols-2 gap-4">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                From Date
              </label>
              <input
                required
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                className="w-full px-4 py-2.5 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                To Date
              </label>
              <input
                required
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                className="w-full px-4 py-2.5 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                School
              </label>
              <Select
                value={selectedSchool}
                onChange={(e) => setSelectedSchool(e.target.value)}
                className="w-full"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#F9FAFB",
                    "&:hover": {
                      "& > fieldset": { borderColor: "#3B82F6" },
                    },
                  },
                }}
              >
                <MenuItem value="all">All Schools</MenuItem>
                {schoolsData &&
                  schoolsData.map((school, index) => (
                    <MenuItem key={index} value={school.id}>
                      {school.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Model
              </label>
              <Select
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
                className="w-full"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#F9FAFB",
                    "&:hover": {
                      "& > fieldset": { borderColor: "#3B82F6" },
                    },
                  },
                }}
              >
                <MenuItem value="all">All Models</MenuItem>
                <MenuItem value="gpt-4">GPT-4</MenuItem>
                <MenuItem value="gpt-4o">GPT-4O</MenuItem>
                <MenuItem value="gpt-4o-mini">GPT-4O Mini</MenuItem>
              </Select>
            </div>

            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                STT Model
              </label>
              <Select
                value={selectedSttModel}
                onChange={(e) => setSelectedSttModel(e.target.value)}
                className="w-full"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#F9FAFB",
                    "&:hover": {
                      "& > fieldset": { borderColor: "#3B82F6" },
                    },
                  },
                }}
              >
                <MenuItem value="all">All STT Models</MenuItem>
                <MenuItem value="whisper">Whisper</MenuItem>
              </Select>
            </div>

            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                TTS Model
              </label>
              <Select
                value={selectedTtsModel}
                onChange={(e) => setSelectedTtsModel(e.target.value)}
                className="w-full"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#F9FAFB",
                    "&:hover": {
                      "& > fieldset": { borderColor: "#3B82F6" },
                    },
                  },
                }}
              >
                <MenuItem value="all">All TTS Models</MenuItem>
                <MenuItem value="TTS">TTS</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center mt-10">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      ) : (
        dashboardData && (
          <div className="p-2 border-t-2 mt-5 pt-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
              {cardData.map((card, index) => (
                <DashboardCard key={index} {...card} />
              ))}
            </div>

            <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-4 rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-4">
                  Question Types Distribution
                </h2>
                <div className="h-[400px] flex justify-center items-center">
                  <Pie
                    data={prepareQuestionTypeData()}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      plugins: {
                        legend: {
                          position: "bottom",
                          labels: {
                            padding: 15,
                            usePointStyle: true,
                          },
                        },
                        datalabels: {
                          display: true,
                          color: "#fff",
                          font: {
                            weight: "bold",
                            size: 14,
                          },
                          formatter: (value, ctx) => {
                            const dataset = ctx.chart.data.datasets[0];
                            const total = (dataset.data as number[]).reduce(
                              (acc, data) => acc + data,
                              0
                            );
                            const percentage = (
                              ((value as number) * 100) /
                              total
                            ).toFixed(1);
                            return `${value}\n(${percentage}%)`;
                          },
                          textAlign: "center",
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div className="bg-white p-4 rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-4">
                  Cost Distribution
                </h2>
                <Bar
                  data={prepareCostData()}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            const dataset = context.dataset as CustomDataset;
                            const value = context.raw as number;
                            const usage = dataset.usage[context.dataIndex];
                            return `${usage} = $${value.toFixed(4)}`;
                          },
                        },
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            </div>

            {ratingsData && ratingsData.length > 0 && (
              <div className="mt-8">
                <div className="bg-white p-4 rounded-lg shadow">
                  <h2 className="text-lg font-semibold mb-4">Course Ratings</h2>
                  <Bar
                    data={prepareRatingsData()}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "top",
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              return `Rating: ${context.raw} Stars`;
                            },
                          },
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          max: 5,
                          ticks: {
                            stepSize: 1,
                            callback: function (value) {
                              return `${value} Stars`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )
      )}
    </section>
  );
}

export default AdminDashboard;
