import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { RiSubtractFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";

function SAEditLevel({ setRefreshUserData, userData }: any) {
  interface UsersData {
    id: string;
    name: string;
  }

  const { grade_name } = useParams();
  const { state } = useLocation();

  const [levelId, setLevelId] = useState<string>(() => {
    const storedLevelId = window.localStorage.getItem("levelId") || "";
    return (state && state.level_id) || storedLevelId;
  });
  const [contractLevelId, setContractLevelId] = useState<string>(() => {
    const storedContractLevelId =
      window.localStorage.getItem("contractLevelId") || "";
    return (state && state.contract_level_id) || storedContractLevelId;
  });

  const [schoolId, setSchoolId] = useState<string>(() => {
    const storedSchoolId = window.localStorage.getItem("schoolId") || "";
    return (state && state.school_id) || storedSchoolId;
  });

  const [usersData, setUsersData] = useState<UsersData[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UsersData[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [tabChange, setTabChange] = useState("students");

  useEffect(() => {
    setFilteredUsers([]);
    setUsersData([]);
    setSelectedUsers([]);
    async function fetchData1() {
      try {
        const formData = new FormData();

        formData.append(
          "school_id",
          schoolId === "" ? userData?.school_id : schoolId
        );
        formData.append("level_id", levelId);
        formData.append(
          "type",
          tabChange === "students" ? "student" : "teacher"
        );

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_STUDENTS_BY_LEVEL_ID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setSelectedUsers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    async function fetchData2() {
      try {
        const formData = new FormData();
        formData.append(
          "school_id",
          schoolId === "" ? userData?.school_id : schoolId
        );
        formData.append("level_id", levelId);
        formData.append(
          "type",
          tabChange === "students" ? "student" : "teacher"
        );

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_LEVEL_STUDENTS_BY_SCHOOL_ID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setUsersData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();
    fetchData2();
  }, [refresh, tabChange]);

  useEffect(() => {
    setFilteredUsers(
      usersData
        .filter((user) =>
          user.name.toLowerCase().includes(usersSearchTerm.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [usersData, usersSearchTerm]);

  const handleUserSelect = (id: string) => {
    setSelectedUsers((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((userId) => userId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("level_id", levelId);
    formData.append("contract_level_id", contractLevelId);
    formData.append(
      "school_id",
      schoolId === "" ? userData?.school_id : schoolId
    );
    formData.append("user_ids", JSON.stringify(selectedUsers));
    formData.append("type", tabChange === "students" ? "student" : "teacher");

    try {
      let endpoint = `${process.env.REACT_APP_ASSIGN_LEVEL_STUDENTS}`;
      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefreshUserData((prevRefresh: any) => prevRefresh + 1);
      setRefresh((prevRefresh: any) => prevRefresh + 1);

      // window.location.href = `/classes`;
      toast.success("Grade has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An unexpected error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };
  return (
    <div>
      <div className="relative">
        <div className="absolute">
          <Link to={"/school_grades"}>
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="subscription" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          {grade_name}
        </p>
        <div className="flex flex-row gap-2 w-full py-2">
          <div
            className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
              tabChange === "students"
                ? "bg-[#8462ff] text-white"
                : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
            }`}
            onClick={() => {
              setTabChange("students");
            }}
          >
            Students
          </div>
          <div
            className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
              tabChange === "teachers"
                ? "bg-[#8462ff] text-white"
                : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
            }`}
            onClick={() => {
              setTabChange("teachers");
            }}
          >
            Teachers
          </div>
        </div>
        <form className="w-full" onSubmit={handleEditSubmit}>
          <div className="p-4 flex flex-col gap-4">
            <div
              className="flex flex-col"
              style={{ maxHeight: "calc(100vh - 310px)" }}
            >
              {tabChange === "students" ? (
                <label className="pb-2 text-[20px]">Students:</label>
              ) : (
                <label className="pb-2 text-[20px]">Teachers:</label>
              )}
              <input
                type="text"
                placeholder="Search by name"
                value={usersSearchTerm}
                onChange={(e) => setUsersSearchTerm(e.target.value)}
                className="border-[2px] text-black outline-none p-1 rounded-lg"
              />
              <div className="rounded-lg w-full mt-2 overflow-scroll">
                {filteredUsers && filteredUsers.length > 0 && (
                  <table className="w-full">
                    <thead className="bg-black text-white">
                      <tr>
                        <th className="w-1/5 text-left p-2">ID</th>
                        <th className="w-3/5 text-left p-2">Name</th>
                        <th className="w-1/5 text-left p-2">Action</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-black border-black border-b-4 ">
                      {filteredUsers.map(
                        (user, index) =>
                          selectedUsers.includes(user.id) && (
                            <tr key={user.id}>
                              <td className="p-2">{user.id}</td>
                              <td className="p-2">{user.name}</td>
                              <td className="p-2">
                                <button
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    handleUserSelect(user.id);
                                  }}
                                  className=" bg-red-600 hover:bg-red-800 text-white p-1 rounded duration-300"
                                >
                                  <RiSubtractFill />
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tbody className="divide-y divide-black ">
                      {filteredUsers.map(
                        (user, index) =>
                          !selectedUsers.includes(user.id) && (
                            <tr key={user.id}>
                              <td className="p-2">{user.id}</td>
                              <td className="p-2">{user.name}</td>
                              <td className="p-2">
                                <button
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    handleUserSelect(user.id);
                                  }}
                                  className=" bg-green-600 hover:bg-green-800 text-white p-1 rounded duration-300"
                                >
                                  <IoMdAdd />
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            <div className=" flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default SAEditLevel;
