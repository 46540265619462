import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { ImSpinner } from "react-icons/im";
import api from "../../../../api";
import { fetchData } from "../../../../GlobalFunctions";

function FeedbackSessions({ setRefresh, userData }: any) {
  interface ChaptersData {
    id: string;
    title: string;
  }

  const { course_name } = useParams();
  const [chaptersData, setChaptersData] = useState<ChaptersData[]>([]);

  const [loading1, setLoading1] = useState(true);
  const { state } = useLocation();
  const [gradeName, setGradeName] = useState<string>(() => {
    const storedGradeName = window.localStorage.getItem("gradeName") || "";
    return (state && state.grade_name) || storedGradeName;
  });
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });

  const user_id = localStorage.getItem("user_id") || "default_user";

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_CHAPTER_FILTER}${courseId}`,
      setChaptersData
    );

    setLoading1(false);
  }, [user_id]);

  if (loading1) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center gap-1 mb-2 w-full">
        <Link
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={"/feedback-courses"}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px] w-full">
          {course_name?.toLowerCase().includes("ai")
            ? `AI & Robotics ${gradeName
                .toLowerCase()
                .replace("grade", "")} - Part I`
            : `AI & Robotics ${gradeName
                .toLowerCase()
                .replace("grade", "")} - Part II`}
          :
        </p>
      </div>

      <div className="relative h-screen" style={{ maxHeight: "calc(100vh )" }}>
        <div className="p-4 flex flex-col gap-4 overflow-scroll h-screen">
          <div className=" overflow-x-auto shadow-md sm:rounded-lg mb-36">
            <table className="w-full text-sm text-left ">
              <thead className="border-b bg-gray-300">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {chaptersData?.map((chapter, index) => (
                  <tr key={index} className="bg-gray-200 border-b ">
                    <td className="px-6 py-4">{chapter.title}</td>
                    <td className="px-6 py-4 flex flex-row gap-2">
                      <Link
                        state={{
                          course_id: courseId,
                          chapter_id: chapter.id,
                          grade_name: gradeName,
                        }}
                        to={`/feedback-courses/view-course/${course_name}/view-session/${chapter.title}`}
                        className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                      >
                        View Feedback
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeedbackSessions;
